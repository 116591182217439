<template>
  <div class="wood_cont">
    <img class="wood" src="../assets/Upgrade_assets/upgrade.png" />
  </div>
  <!--Clans -->
  <div class="clans" v-if="page">
    <div class="wanted_box"><img src="../assets/MoneyImages/wanted.png" class = "adapt_size"/></div>
    <div class="_frame">
      <div class="block" v-for="elem in clans_list" v-bind:key="elem">
        <img src="../assets//MoneyImages/clan_img.png" />
        <div class="block_text">
          <span>{{ elem.name }}</span>
          <span>{{ elem.silver }} silver</span>
          <span>{{ elem.clan_members }} members</span>
        </div>
      </div>
    </div>
  </div>

  <!--User status-->
  <div class="clans" v-if="!page">
    <div class="wanted_box"><img src="../assets/MoneyImages/wanted.png" class = "adapt_size"/></div>
    <div class="_frame">
      <div class="block" v-for="elem in stat" v-bind:key="elem">
        <img src="../assets/MoneyImages/person1.svg" />
        <div class="block_text">
          <span>{{ elem.username }}</span>
          <span>{{ elem.silver }} silver</span>
        </div>
        <div class="block_text">
          <span>level {{ elem.horse_lvl }}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="z-button" @click="Switch_view()" ><img src = "../assets/TopBar_assets/globus.svg"></div> 
</template>

<style>

.adapt_size{
  width: 90vw;
  height: 70vh;

}

.clans {
  width: 100vw;
  height: 100vh;
  display: flex;
  position: relative;
  font-family: "AL";
  font-weight: 400;
  font-size: 14px;
  color: rgba(54, 29, 17, 1);

  z-index: 20;
  flex-direction: column;
  justify-content: center;
}

.z-button{
  position: absolute;
  z-index: 20;
  top:77.5vh;
  align-self: flex-end;
  right: 17vw;
}

._frame {
  width: 100vw;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  z-index: 20;
}
.clans_frame {
  justify-content: center;
}

.players {
  top: 100px;
  color: rgba(54, 29, 17, 1);
}
.players ._frame {
  position: relative;
  justify-content: center;
}

.players .block_text {
  bottom: 10px;
  position: relative;
}
.block_text span {
  align-self: flex-start;
}

.wanted_box {
  position: absolute;

  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 80vh;
  width: 350px;
}
.wanted_box img {
  align-self: center;
}

@keyframes Scale {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.9);
  }
}
</style>

<script>
import axios from "axios";

export default {
  name: "StatisticsView",
  data() {
    return {
      //loaders
      img_loading: 0,
      imgs_loaded: false,
      api_responsed: false,

      //functional
      page: true,

      //data
      clans_list: [
        {
          clan_members: -1,
          id: 0,
          name: "Super_clan_for_testers_only",
          silver: "666.666.666",
        },
      ],
      stat: 
      [
        {
          "horse_lvl": 555,
          "id": 999,
          "silver": 666,
          "username": "Tester #1",
        },
      ],
    };
  },
  async mounted() {
    // this.$emit("component_loading", { name: "ClansView" });
    let images = document.querySelectorAll("img");
    this.img_loading = images.length;
    for (let item of images) item.onload = this.img_loaded();

    this.getData("stats/clans");
  },
  computed: {
    page_loaded() {
      return this.imgs_loaded;
    },
  },
  watch: {
    page_loaded() {
      this.$emit("component_loading", { name: "StatisticsView" });
    },
  },
  methods: {
    img_loaded() {
      this.img_loading--;
      if (this.img_loading == 0) this.imgs_loaded = true;
    },

    Switch_view(){
      this.page = !this.page;
      this.Refresh();
    },

    Refresh() {
      this.getData("stats/clans");
      this.getData("stats/users");
    },

    //api
    async getData(link) {
      this.initData = window.Telegram.WebApp.initData;

      axios.defaults.headers.common["X-User-Data"] = `${this.initData}`;

      await axios
        .get("https://cryptocowboy.ru/api/v1/" + link)
        .then((response) => {
          if (link == "stats/clans")
            this.clans_list = response.data;
          else if (link == "stats/user")
            this.stat = response.data;
          console.log(`on get ${link}: `, response);
        })
        .catch((error) => {
          error;
          console.log(`on get ${link}: `, error);
        });
    },
  },
};
</script>

<template>
  <!--Clans - default page-->
  <div v-if="page_type == 1">
    <!--clan create modal window -->
    <div class="centring shadow_end leave" v-if="create_clan_page_visible">
      <div class="frame">
        <div class="skull img_wait enter_name">
          <img src="../assets/clans/enter_clan_name.svg"/>
        </div>
        <div class="cross_but"><img src = "../assets/clans/button_cross.svg"></div>
        <div class="money_box">
          <div class="m_rec">
            <input v-model="clan.stats.name" placeholder="ENTER CLAN"/>
            <div class="s_m_rec"></div>
          </div>
        </div>
        
        <div class="continue_button" @click="ConfirmName()"></div>
      </div>
    </div>

    <div class="wood_cont create_clan">
      <img class="wood" src="../assets/Upgrade_assets/upgrade.png"/>
    </div>
    <div class="clans">
      <!--clan create modal window - create -->
      <div class="make_clan">
        <img src="../assets/clans/clans_frame.png" class="make-clan-frame"/>
        <div class="make_clans_box">
          <form><input type="text" value="ENTER CLAN NAME" onfocus="this.value=''"/> <br/></form>
          <div class="make-clan-small-box">
            <div class="mini_button" @click="Search()">
              <img src="../assets/clans/search.svg"/>
            </div>
            <div class="mini_button" @click="CreateClan()">
              <img src="../assets/clans/change.svg"/>
            </div>
          </div>
        </div>
      </div>

      <!--clans list-->
      <div class="_frame white scroll-add">
        <div class="block" v-for="elem in clans_list" v-bind:key="elem" v-on:click="VisitClanPage(elem.ID)">
          <img src="../assets//MoneyImages/clan_img.png"/>
          <div class="block_text">
            <span>{{ elem.name }}</span>
            <span>{{ elem.silver }} silver</span>
            <span>{{ elem.members }} members</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--Clan for owner/user-->
  <div v-if="page_type == 2">

    <!--clan leave modal window -->
    <div class="shadow_end leave" @click="CancelLeave()" v-if="leave_visible">
      <div class="frame">
        <div class="skull img_wait"><img src="../assets/pistons_svg.svg"></div>
        <div class="money_box">
          <span>Want to leave?</span>
        </div>
        <div class="continue_button" @click="ConfirmLeave()"></div>
      </div>
    </div>

    <div class="wood_cont create_clan">
      <img class="wood" src="../assets/Upgrade_assets/upgrade.png"/>
    </div>
    <div class="clans clans-page">
      <!--clan change modal window -->
      <div class="centring shadow_end leave" v-if="change_clan_page_visible">
        <div class="frame">
          <div class="skull img_wait enter_name">
            <img src="../assets/clans/enter_clan_name.svg"/>
          </div>
          <div class="cross_but"><img src = "../assets/clans/button_cross.svg"></div>
          <div class="money_box">
            <div class="m_rec">
              <input v-model="tmp_clan_name" placeholder="ENTER CLAN"/>
              <div class="s_m_rec"></div>
            </div>
          </div>

          <div class="continue_button" @click="ChangeName()"></div>
        </div>
      </div>
      <!--clan info-->
      <div class="make_clan">
        <img src="../assets/clans/clans_frame.png" class="make-clan-frame"/>
        <div class="clans-page-box">
          <span>{{ clan.stats.name }}</span>
          <span>{{ clan.stats.silver }} silver</span>
          <span>{{ clan.stats.number_clan_members }} members</span>
          <span>{{ clan.owner.name }}</span>
          <span>{{ clan.owner.silver }} silver</span>
          <span>{{ clan.owner.horse_level }} lvl</span>
          <!--buttons-->
          <div v-if="clan.type == 'user'" class="mini_button" @click="JoinClan()"><img src="../assets/clans/btn_plus.svg"/></div>
          <div v-if="clan.type == 'owner'" class="mini_button" @click="EditClan()"><img src="../assets/clans/change.svg"/></div>
          <div v-if="clan.type == 'member'" class="mini_button" @click="LeaveClan()"><img src="../assets/clans/cross.svg"/></div>
        </div>
      </div>
      <!--members list-->
      <div class="_frame white scroll-add">
        <div class="block" v-for="elem in clan_members" v-bind:key="elem">
          <img src="../assets/MoneyImages/person1.svg"/>
          <div class="block_text">
            <span>{{ elem.name }}</span>
            <span>{{ elem.silver }} silver</span>
          </div>
          <div class="block_text">
            <span>level {{ elem.horse_lvl }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>

.centring{
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

}

.cross_but{
  position: absolute;
  width: 30px;
  height: 30px;
  margin-left: 160px;
  margin-bottom: 100px;
  z-index: 20;
}

.cross_but:active{
  -webkit-animation: Scale 0.1s ;
  animation: Scale   0.1s;
  animation-timing-function: ease-in-out;
}


.scroll-add{
  overflow-y: scroll;
  max-height: 500px;
  margin-bottom: 90px;
  z-index: 1;
}
.frame{
  position: relative;
}
.m_rec input {
  outline-width: 0;
  color: white;
  top: 5px;
  position: relative;
  width: 100px;
  height: 20px;
  text-align: center;
}
.clans{
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.create_clan {
  top: 0;
}

.white {
  color: white;
}

.make-clan-frame {
  width: 350px;
  height: 75px;
  position: absolute;
}

.make_clan form {
  position: relative;
  top: 40px;
  border: 2px red;
}

.make_clan input {
  color: rgba(135, 105, 83, 1);
  width: 220px;
}

input[type="text"]:focus {
  outline-width: 0;
}

.make_clan {
  width: 100vw;
  height: 75px;
  display: flex;
  justify-content: center;
  margin-top: 80px;;
  z-index: 2;
}

.make_clans_box {
  width: 300px;
  height: 75px;
  display: flex;
  justify-content: space-around;
}

.make-clan-small-box {
  height: 50px;
  width: 65px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-self: end;
}

.make-clan-small-box .mini_button {
  align-self: center;
}

.leave {
  z-index: 24;
  position: absolute;
}

.leave .continue_button {
  left: 50px;
  top: 110px;
  position: absolute;
}

.leave span {
  font-size: 20px;
  width: 200px;
  top: 60px;
  position: absolute;
}

.leave .m_rec {
  left: 40px;
  top: -10px;
}

.continue_button {
  background-image: url("../assets/svgs/done.svg");
}

.enter_name {
  top: -10px;
}

.clans-page .make-clan-frame {
  height: 150px;
}

.clans-page-box {
  width: 350px;
  height: 150px;
  display: flex;
  flex-direction: column;
  position: relative;
  color: white;
}

.clans-page-box span {
  position: absolute;
}

.clans-page-box span:first-child {
  font-size: 24px;
  top: 20px;
  left: 70px;
  width: 200px;
  text-align: center;
}

.clans-page-box span:nth-child(2) {
  top: 60px;
  left: 25px;
  font-size: 12px;
}

.clans-page-box span:nth-child(3) {
  top: 60px;
  left: 230px;
  font-size: 12px;
}

.clans-page-box span:nth-child(4) {
  top: 85px;
  left: 25px;
  font-size: 18px;
}

.clans-page-box span:nth-child(5) {
  top: 105px;
  left: 25px;
  font-size: 12px;
}

.clans-page-box span:nth-child(6) {
  top: 105px;
  left: 150px;
  font-size: 12px;
}

.clans-page-box .mini_button {
  margin-top: 105px;
  margin-left: 300px;
  z-index: 25;
}

@keyframes Scale{
    0%{
        transform: scale(1);
    }
    100%{
        transform: scale(0.9);
    }
}
</style>

<script>
import axios from "axios";

export default {
  data() {
    return {
      page_type: 1,
      create_clan_page_visible: false,
      change_clan_page_visible: false,
      leave_visible: false,

      tmp_clan_name: "",

      ID: 0,

      clan: {
        "owner": {
          "name": "TEMICH",
          "horse_level": 350,
          "silver": '5,394,880',
          "username": "Temich",
        },
        "stats": {
          "id": 1,
          "isOpen": true,
          "link": "string",
          "name": '',
          "number_clan_members": 211,
          "silver": '5,394,880,059',
        },
        "type": "member",
      },

      clans_list: [
        {
          name: "Clan 1",
          silver: 5000000,
          members: 15,
          ID: 0,
        },
        {
          name: "Clan 2",
          silver: 123,
          members: 125,
          ID: 1,
        },
        {
          name: "Clan 666",
          silver: 666,
          members: 999,
          ID: 1,
        },
        {
          name: "Clan 666",
          silver: 666,
          members: 999,
          ID: 1,
        },
        {
          name: "Clan 2",
          silver: 123,
          members: 125,
          ID: 1,
        },
        {
          name: "Clan 666",
          silver: 666,
          members: 999,
          ID: 1,
        },
        {
          name: "Clan 666",
          silver: 666,
          members: 999,
          ID: 1,
        },
        {
          name: "Clan 2",
          silver: 123,
          members: 125,
          ID: 1,
        },
        {
          name: "Clan 666",
          silver: 666,
          members: 999,
          ID: 1,
        },
        {
          name: "Clan 666",
          silver: 666,
          members: 999,
          ID: 1,
        },
      ],

      clan_members: [
        {
          name: "TOM",
          silver: '3,333',
          horse_lvl: 30,
        },
        {
          name: "TOM",
          silver: '3,333',
          horse_lvl: 30,
        },
        {
          name: "TOM",
          silver: '3,333',
          horse_lvl: 30,
        },
      ],
    };
  },
  mounted() {
    this.$emit("component_loading", { name: "ClansView" });
  },
  watch: {
    page_type(){
      if (this.page_type == 2){
        this.UseApiGet("clans/info" + this.ID);
        this.UseApiPost("clans/members", {
          "clan_id": this.ID,
          "list_amount": 5,
          "page": 1,
        });
      }
    }
  },
  methods: {
    Search() {},

    VisitClanPage(ID){
      this.ID = ID;
      this.clan.type = "user";
      this.page_type = 2;
    },

    JoinClan(){
      this.UseApiPost("clans/join", {"clan_id": this.ID});
    },

    CreateClan() {
      this.create_clan_page_visible = true;

      this.clan.stats.name = "";
      this.clan.description = "no prompt provided";
      this.clan.stats.isOpen = true;
    },

    ConfirmName() {
      if (this.clan.stats.name != "") {
        this.UseApiPost('clans/create', {
          "description": this.clan.description,
          "isOpen": this.clan.stats.isOpen,
          "name": this.clan.stats.name,
        });
        this.clan.type = "owner";
        this.page_type = 2; // for test only
      }
      this.create_clan_page_visible = false;
    },

    EditClan() {
      this.tmp_clan_name = this.clan.stats.name;
      this.change_clan_page_visible = true;
    },

    ChangeName(){
      this.clan.stats.name = this.tmp_clan_name;
      // api
      this.change_clan_page_visible = false;
    },

    LeaveClan() {
      this.leave_visible = true;
    },

    ConfirmLeave() {
      this.UseApiGet("clans/leave", {});
      this.leave_visible = false;
      this.page_type = 1;
    },

    CancelLeave() {
      this.leave_visible = false;
    },

    // api
    async UseApiGet(link) {
      this.initData = window.Telegram.WebApp.initData;

      axios.defaults.headers.common["X-User-Data"] = `${this.initData}`;

      await axios
          .get("https://cryptocowboy.ru/api/v1/" + link)
          .then((response) => {
            console.log("on " + link, response);
            if (link == "clans/info" + this.ID){
              this.clan.type = "member";
              // this.clan = response.data;
            }
          })
          .catch((error) => {console.log("on " + link, error); error});
    },

    async UseApiPost(link, data = {}) {
      this.initData = window.Telegram.WebApp.initData;

      axios.defaults.headers.common["X-User-Data"] = `${this.initData}`;

      await axios
        .post("https://cryptocowboy.ru/api/v1/" + link, data)
        .then((response) => {
          console.log("on " + link, response);
          if (link == "clans/create"){
            this.ID = response.data.id;
            this.page_type = 2;
          } else if (link == "clans/members/"){
            this.clan_members = response.data.members;
          }
        })
        .catch((error) => {console.log("on " + link, error); error});
    },
  },
};
</script>

<template>
  <div class="Race_content" @click="onClick">
    <div class="money_box way">
      <div class="money_img hat_img">
        <img src="../assets/EndGame_assets/hat_img.svg" />
      </div>
      <span class="value">{{ data.distance }}</span>
    </div>

    <div class="money_box timer">
      <div class="money_img clock">
        <img src="../assets/Race_game_assets/clock.svg" />
      </div>
      <span class="value">{{ minutes }}:{{ seconds }}</span>
    </div>

    <canvas id="game"></canvas>

    <End v-if="endgame_visible" :data="data" @setTab="setTab"> </End>
  </div>
</template>

<style>
@import "../styles/RaceStyles";
@import "../styles/Shootout.css";
</style>

<script>
import * as PIXI from "pixi.js";
import axios from "axios";
import End from "../components/EndgameVue.vue";

export default {
  components: {
    End,
  },
  data() {
    return {
      //// page data ////
      data: {
        distance: 0,
        earned: 0,
        record: false,
      },
      endgame_visible: false,

      //// pixi data ////
      app: new PIXI.Application(),

      //// game data ////
      game: false,
      game_interval: null,
      game_upd_speed: 20,

      rendering: true,
      main_interval: null,
      timer: 300,

      horse: null,
      bird: null,
      spawned_cnt: 0,

      hitbox_x_err: 80,
      hitbox_y_err: 50,

      // jump
      jump: false,
      jump_speed: 7,
      jump_delay: 30,
      jump_top: window.innerHeight * 0.55 - 150,
      jump_bottom: window.innerHeight * 0.55,

      // textures
      textures_cnt: 0,
      textures: {
        backgrounds: [],
        horse: {
          run: [],
          jump: [],
          end: [],
        },
        enemies: [],
        bird: [],
      },

      enemies_speed: 6,
      enemies_assets: [
        {
          // bird
          height: 90,
          width: 100,
          y: [window.innerHeight * 0.45, window.innerHeight * 0.65],
        },
        {
          //cactus 1
          height: 60,
          width: 60,
          y: window.innerHeight * 0.72,
        },
        {
          // cactus 2
          height: 120,
          width: 70,
          y: window.innerHeight * 0.66,
        },
        {
          // cactus 3
          height: 130,
          width: 80,
          y: window.innerHeight * 0.65,
        },
        {
          // stone 1
          height: 50,
          width: 70,
          y: window.innerHeight * 0.71,
        },
        {
          // stone 2
          height: 80,
          width: 60,
          y: window.innerHeight * 0.7,
        },
        {
          // stone 3
          height: 120,
          width: 70,
          y: window.innerHeight * 0.65,
        },
      ],

      container_cnt: 0,
      background_assets: [
        {
          width: 1000,
          heigth: window.innerHeight * 0.9,
          x: 0,
          y: 0,
          zIndex: -10,
        },
        {
          width: 1000,
          heigth: window.innerHeight * 0.5,
          x: 0,
          y: window.innerHeight * 0.65,
          zIndex: -9,
        },
      ],
    };
  },
  mounted() {
    // init game
    this.app.init({
      resizeTo: window,
      backgroundAlpha: 0,
      canvas: document.querySelector("#game"),
    });

    // loading
    this.assets_load();
  },
  computed: {
    game_start() {
      return this.textures_cnt == 6;
    },
    minutes() {
      return Math.trunc(this.timer / 60);
    },
    seconds(){
      let t = String(Math.trunc(this.timer - Math.trunc(this.timer / 60) * 60));
      return (t.length < 2 ? '0' + t : t);
    },
  },
  watch: {
    game_start() {
      this.render();
    },
  },
  methods: {
    getRandomArbitrary(min, max) {
      let rnd = Math.floor(Math.random() * (max - min) + min);
      return rnd;
    },

    setTab(tab) {
      this.$emit("setTab", tab);
    },

    ///// {game section} /////

    // preload
    async load(images, from, to, template) {
      let assets = [];
      for (let i = from, len = 0; i < to; i++, len++)
        assets[len] = await PIXI.Assets.load(images(`./${template}${i}.png`));
      return assets;
    },

    assets_load() {
      if (this.textures_cnt < 6) {
        //background
        new Promise((resolve) => {
          resolve(
            this.load(
              require.context(
                "@/assets/Race_game_assets/backgrounds",
                false,
                /\.png$/
              ),
              1,
              3,
              "bg-"
            )
          );
        }).then((_texture) => {
          this.textures.backgrounds = _texture;
          this.textures_cnt += 1;
        });

        //enemies
        new Promise((resolve) => {
          resolve(
            this.load(
              require.context(
                "@/assets/Race_game_assets/enemies",
                false,
                /\.png$/
              ),
              1,
              8,
              "enemy-"
            )
          );
        }).then((_texture) => {
          this.textures.enemies = _texture;
          this.textures_cnt += 1;
        });

        //horse running
        new Promise((resolve) => {
          resolve(
            this.load(
              require.context(
                "@/assets/Race_game_assets/horse_animations/horse_run",
                false,
                /\.png$/
              ),
              1,
              15,
              "run-"
            )
          );
        }).then((_texture) => {
          this.textures.horse.run = _texture;
          this.textures_cnt += 1;
        });

        //horse jumping
        new Promise((resolve) => {
          resolve(
            this.load(
              require.context(
                "@/assets/Race_game_assets/horse_animations/horse_jump",
                false,
                /\.png$/
              ),
              1,
              8,
              "jump-"
            )
          );
        }).then((_texture) => {
          this.textures.horse.jump = _texture;
          this.textures_cnt += 1;
        });

        //horse end
        new Promise((resolve) => {
          resolve(
            this.load(
              require.context(
                "@/assets/Race_game_assets/horse_animations/horse_endgame",
                false,
                /\.png$/
              ),
              22,
              30,
              "end-"
            )
          );
        }).then((_texture) => {
          this.textures.horse.end = _texture;
          this.textures_cnt += 1;
        });

        //bird animations
        new Promise((resolve) => {
          resolve(
            this.load(
              require.context(
                "@/assets/Race_game_assets/bird_animations",
                false,
                /\.png$/
              ),
              1,
              8,
              "bird-"
            )
          );
        }).then((_texture) => {
          this.textures.bird = _texture;
          this.textures_cnt += 1;
        });
      } else {
        this.restart();
      }
    },

    create_horse() {
      this.horse = new PIXI.AnimatedSprite(this.textures.horse.run);

      this.horse.x = 0;
      this.horse.y = this.jump_bottom;
      this.horse.width = 210;
      this.horse.height = 210;
      this.horse.id = "horse";
      this.horse.animationSpeed = 0.4;
      this.horse.play();

      this.app.stage.addChild(this.horse);
    },

    init_bird(){
      this.bird = new PIXI.AnimatedSprite(this.textures.bird);
      this.bird.animationSpeed = 0.1;

      this.bird.play();
    },

    create_bg(idx, x) {
      let bg = this.background_assets[idx];
      let new_bg = new PIXI.Sprite(this.textures.backgrounds[idx]);

      new_bg.x = x;
      new_bg.y = bg.y;
      new_bg.height = bg.heigth;
      new_bg.width = bg.width;
      new_bg.data = idx;
      new_bg.zIndex = bg.zIndex;

      return new_bg;
    },

    create_container(x) {
      this.container_cnt += 1;
      let new_container = new PIXI.Container();
      new_container.id = "container";
      new_container.x = x;
      new_container.zIndex = -1;

      for (let i = 0; i < 2; i++) new_container.addChild(this.create_bg(i, 0));

      this.app.stage.addChild(new_container);
    },

    render() {
      this.init_bird();
      this.create_container(0);
      this.start();

      this.spawn_entity(0, 20);

      setTimeout(() => {
        this.create_horse();
        this.horse_running_animation();

        setTimeout(() => {
          this.jump = true;
          this.jump_up(this.jump_speed, this.jump_delay);

          setTimeout(() => {
            this.rendering = false;
            this.$emit("component_loading", { name: "RaceGameView" });
          }, 1400);
        }, 500);
      }, 500);
    },

    // game

    restart() {
      let items = this.app.stage.children;
      let removeItems = [];

      for (let item of items)
        if (item.id == "enemy") removeItems.push(item);
      
      for (let item of removeItems)
        this.app.stage.removeChild(item);

      this.data.distance = 0;
      this.timer = 300;

      this.endgame_visible = false;

      setTimeout(() => {
        this.horse_running_animation();
        this.start()
      }, 100);
    },

    start() {
      this.game = true;

      this.main_interval = setInterval(() => {
        if (!this.rendering){
          this.data.distance += 1;
          this.timer -= 0.5;
          if (this.timer <= 0) this.end();
        }
      }, 500);

      this.game_interval = setInterval(() => {
        this.game_upd();
      }, this.game_upd_speed);

      this.spawner();
    },

    game_upd() {
      let items = this.app.stage.children;
      let removeItems = [];

      for (let item of items) {
        if (item.id == "enemy") {
          if (item.x + item.width <= 0) {
            removeItems.push(item);
          } else {
            item.x -= this.enemies_speed;
            if (!this.rendering && this.has_collsion(item) == true) {
              this.end();
            }
          }
        } else if (item.id == "container") {
          item.x -= this.enemies_speed;
          if (item.x + item.width <= 0) {
            removeItems.push(item);
            this.container_cnt -= 1;
          } else if (
            item.x + item.width <= window.innerWidth &&
            this.container_cnt <= 1
          ) {
            this.create_container(item.x + item.width);
          }
        }
      }
      for (let item of removeItems) {
        this.app.stage.removeChild(item);
      }
    },

    end() {
      this.game = false;

      clearInterval(this.main_interval);
      clearInterval(this.game_interval);

      this.horse_ending_animation();

      this.sendData("horses/finish");
    },

    // horse animations
    horse_running_animation() {
      this.horse.textures = this.textures.horse.run;
      this.horse.animationSpeed = 0.4;
      this.horse.play();
    },

    horse_jump_up_animation(){
      this.horse.textures = this.textures.horse.jump.slice(0, 3);
      this.horse.animationSpeed = 0.1;
      this.horse.play();
    },

    horse_jump_down_animation(){
      this.horse.textures = this.textures.horse.jump.slice(3, -1);
      this.horse.animationSpeed = 0.1;
      this.horse.play();
    },

    horse_ending_animation() {
      this.horse.textures = this.textures.horse.end;
      this.horse.animationSpeed = 0.08;
      this.horse.play();
    },

    // enemy spawner
    spawn_entity(idx, x = 0) {
      let new_enemy = null;

      if (idx == 0) {
        let pos = this.getRandomArbitrary(0, 2);
        new_enemy = this.bird;
        new_enemy.y = this.enemies_assets[0].y[pos];
      } else {
        new_enemy = new PIXI.Sprite(this.textures.enemies[idx]);
        new_enemy.y = this.enemies_assets[idx].y;
      }
      new_enemy.height = this.enemies_assets[idx].height;
      new_enemy.width = this.enemies_assets[idx].width;
      new_enemy.id = "enemy";

      if (x == 0)
        new_enemy.x = window.innerWidth + this.enemies_assets[idx].width * 1.5;
      else  {
        new_enemy.x = x;
        new_enemy.y = this.enemies_assets[0].y[0];
        this.app.stage.addChild(new_enemy);
      }

      if (!this.rendering)
        this.app.stage.addChild(new_enemy);
    },

    spawner() {
      if (this.game) {
        let rnd = this.getRandomArbitrary(2800, 5000);
        setTimeout(() => {
          let idx = (this.spawned_cnt > 0 ? this.getRandomArbitrary(0, 7) : this.getRandomArbitrary(1, 7));
          this.spawn_entity(idx);
          this.spawned_cnt += 1;
          this.spawner();
        }, rnd);
      }
    },

    // collision
    has_collsion(item) {
      const bounds1 = this.horse.getBounds();
      const bounds2 = item.getBounds();

      return (
        bounds1.x + this.hitbox_x_err < bounds2.x + bounds2.width &&
        bounds1.x + bounds1.width - this.hitbox_x_err > bounds2.x &&
        bounds1.y + this.hitbox_y_err < bounds2.y + bounds2.height &&
        bounds1.y + bounds1.height - this.hitbox_y_err > bounds2.y
      );
    },

    // horse jump
    jump_up(speed, delay) {
      let top = this.jump_top;
      this.horse_jump_up_animation();
      let jump_timer = setInterval(() => {
        if (this.horse.y > top) this.horse.y -= speed;
        if (this.horse.y <= top || !this.game) {
          clearInterval(jump_timer);
          this.jump_wait(speed, delay);
        }
      }, delay);
    },

    jump_wait(speed, delay) {
      setTimeout(() => {
        this.jump_down(speed, delay);
      }, 40);
    },

    jump_down(speed, delay) {
      let bottom = this.jump_bottom;
      if (this.game) this.horse_jump_down_animation();
      let jump_timer = setInterval(() => {
        if (this.horse.y < bottom) this.horse.y += speed;
        if (this.horse.y >= bottom) {
          clearInterval(jump_timer);
          this.jump = false;
          if (this.game) this.horse_running_animation();
        }
      }, delay);
    },

    onClick() {
      if (!this.jump && this.game && !this.rendering) {
        this.jump = true;
        this.jump_up(this.jump_speed, this.jump_delay);
      }
    },

    //// api ////
    async sendData(link) {
      this.initData = window.Telegram.WebApp.initData;

      axios.defaults.headers.common["X-User-Data"] = `${this.initData}`;

      await axios
        .post("https://cryptocowboy.ru/api/v1/" + link, {
          distance: this.data.distance,
        })
        .then((response) => {
          this.data = response.data;
          this.endgame_visible = true;
          //console.log(response);
        })
        .catch((error) => {
          error;
          //console.log(error);
          this.endgame_visible = true;
        });
    },
  },
};
</script>

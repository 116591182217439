<template>
    <div class = "shadow_end">
        <!-- <div class="money_box way">
                    <div class="money_img hat_img"></div>
                    <span class="value">{{distance}}</span>
        </div> -->
        <div class = "frame fra_k">
        
            <div class = "skull"><img src = "../assets/EndGame_assets/skull.png"> </div>
            <div class="center_menu">
                <div class="money_box">
                    <div class = "m_rec">    <div class = "s_m_rec"></div>  </div>

                    <div class="money_img hat_img"><img src = '../assets/EndGame_assets/hat_img.svg'></div>
                    <span class="value">{{distance}}</span>
                    <div class = "new_record" v-if="record"> </div>
                </div>

                <div class="money_box">
                    <div class = "m_rec">   <div class = "s_m_rec"></div></div>
                    <div class="money_img silver_img"><img src="../assets/silver.svg"></div>
                    <span class="value val_f">{{earned}}</span>
                </div>
                <div class = "continue_button" @click="link_timeout('MainMenuView', 100)"></div>
            </div>
        </div>
    </div>
</template>

<style>
@import '../styles/EndGame.css';
/* @import '../styles/TopBarStyles.css' */
</style>

<script>

export default {
    props: ['data'],
    data() {
        return {
            distance: this.data.distance,
            earned: this.data.earned,
            record: this.data.record,
        }
    },
    methods: {
        link_timeout(link, timeout) {
            setTimeout(() => {
                this.$emit("setTab", link);
            }, timeout);
        },
    }
}

</script>
<template>
  <div class="up">
    <div class="left_menu">
      <div class="mini_button menu_button"><img src = "../assets/TopBar_assets/menu.svg"></div>

      <div v-if="visible">
          <div class="mini_button back_button" @click="BackButtonClick()"><img src = "../assets/TopBar_assets/back.svg"></div>
      </div>
    </div>
    <div class = "right_box">
      <div class="center_menu">
        <div class="money_box">
          <div class="money_img silver_img"><img src="../assets/silver.svg"></div>
          <span class="value">{{ silver }}</span>
          <div class="plus" @click="activateDonatePage()"><img src = "../assets/TopBar_assets/donate.svg"></div>
        </div>
        <div class="money_box">
          <div class="money_img gold_img"><img src = "../assets/gold.svg"></div>
          <span class="value">{{ gold }}</span>
          <div class="plus" @click="activateDonatePage()"><img src = "../assets/TopBar_assets/donate.svg"></div>
        </div>
      </div>

      <div class="right_menu">
          <div class="mini_button settings_button"><img src = "../assets/TopBar_assets/_custbutton.svg"></div>
      </div>
    </div>
  </div>
</template>

<style>
@import "../styles/TopBarStyles.css";
</style>

<script>
import axios from "axios";

export default {
  name: "TopBar",
  data() {
    return {
      silver: 0,
      gold: 0,
      visible: false,
    };
  },
  mounted(){
    this.getData("money");
  },
  methods: {
    async Refresh(){ // outside refresh func for App.vue
      this.getData("money");
    },

    activateDonatePage(){
      this.visible = true;
      this.$emit("VisibilityToggler", {ComponentName: "DonatePage", visible: true});
    },

    BackButtonClick(){
      this.visible = false;
      this.$emit("VisibilityToggler", {ComponentName: "DonatePage", visible: false});
    },

    //api
    async getData(link) {
      this.initData = window.Telegram.WebApp.initData;

      axios.defaults.headers.common["X-User-Data"] = `${this.initData}`;

      await axios
        .get("https://cryptocowboy.ru/api/v1/" + link)
        .then((response) => {
          this.gold = response.data.gold; 
          this.silver = response.data.silver; 
          // console.log("on get /money: ", response);
        })
        .catch((error) => {
          error;
          //console.log("on get /money: ", error)
        });
    },

    //router
    link_timeout(link, timeout) {
      setTimeout(() => {this.$emit("setTab", link)}, timeout);
    },
  },
};
</script>

<template>
  <div class="shadow_end choose  ">
    <div class="frame">
      <div class="skull img_wait"><img src = "../assets/pistons_svg.svg"></div>
      <div class="money_box">
        <div class="m_rec">
          <span class="value">{{ value }}</span>
          <div class="s_m_rec"></div>
        </div>
        <div class="money_img coin"></div>
      </div>
      <div class="shkala" @:click="PointMove">
        <div :style="chooser"><img src = "../assets/point_sv.svg"></div>
        <div class="nums"><img src = "../assets/shkala_sv.svg"></div>
      </div>
      <div class="continue_button" @click="confirm()"></div>
    </div>
  </div>
</template>

<style>
.shadow_end{
  height: 120vh;
  position: absolute;
}

.choose .frame{
  position: relative;
  display: flex;
  justify-content: center;
}
.m_rec .value{
  position: absolute;
  top:8px;
}
.choose .money_box {
  position: relative;
  align-self: center;
  top:20px;
}
.shkala {
  width: 175px;
  height: 30px;
  position: relative;
  top:30px;
  align-self: center;
}
.choose .continue_button {
  top: 0px;
  bottom: 40px;
  background-size: cover;
  background-image: url("../assets/EndGame_assets/continue.png");
}
.choose .frame {
  height: 170px;
  background-size: contain;
}
.nums {
  width: 140px;
  height: 30px;
  position: absolute;
}
/* .chooser {
  height: 10px;
  width: 10px;
  position: absolute;
  bottom: 26px;
  z-index: 100;
} */
.choose .continue_button{
  align-self: center;
  top:30px;
}
.coin{
  background-image: url('../assets/gold.svg');
  top:0px;
}
</style>

<script>

export default {
  data() {
    return {
      triggered: false,
      move_point: null,
      position: 95,
      value: 1000,
    }
  },
  computed: {
    chooser(){
      return {
        "height": '25px',
        "width": '25px',
        "position": 'absolute',
        "bottom": "16px",
        "z-index": 100,
        "left": `${this.position}px`,
      }
    },
  },
  watch: {
    triggered(){
      if (this.triggered){
        this.move_point = setInterval(() => this.PointMove(), 100);
      } else {
        clearInterval(this.move_point);
      }
    },
  },
  methods: {
    confirm() {
      setTimeout(() => this.$emit("local_event", "GameBet", this.value), 200);
    },

    PointMove(event){
      if (event.layerX - 10 < 155)
        this.position = event.layerX - 10;

      if (this.position < -5){
        this.value = 10;
      } else if (this.position < 10){
        this.value = 25;
      } else if (this.position < 20){
        this.value = 50;
      } else if (this.position < 30){
        this.value = 75;
      } else if (this.position < 40){
        this.value = 100;
      } else if (this.position < 55){
        this.value = 250;
      } else if (this.position < 70){
        this.value = 300;
      } else if (this.position < 85){
        this.value = 500;
      } else if (this.position < 100){
        this.value = 1000;
      } else if (this.position < 115){
        this.value = 2500;
      } else if (this.position < 130){
        this.value = 5000;
      } else if (this.position < 155){
        this.value = 10000;
      }
    }
  },
};
</script>
<template>
  <div class="shadow_end wait">
    <div class="frame">
      <div class="skull img_wait"><img src = "../assets/waiting_svg.svg"></div>
      <div class="money_box">
        <div class="m_rec">
          <span class="value">{{ minutes }}:{{ seconds }}</span>
          <div class="s_m_rec"></div>
        </div>

        <div class="money_img "></div>
      </div>

      <div class="continue_button" @click="cancel(0)"></div>
    </div>
  </div>
</template>

<style>
.wait .continue_button {
  background-image: url("../assets/cancel.png");
  align-self: center;
  top: 20px;
}
.frame{
  position: relative;
}
.img_wait {
  /* background-image: url("../assets/waiting_svg.svg"); */
  width: 160px;
  height: 80px;
  align-self: center;
  top:-35px;
  position: absolute;
  left: 10px;
}
.wait .value {
  bottom: 10px;
  text-align: center;
  position: absolute;

}
.wait .money_box {
  align-self: center;
  margin-top: 40px;
}
.wait .money_img {
  background-image: url("../assets/clock.svg");
  height: 32px;
  background-size: cover;
  position: absolute;
  top:63px;
}
.shadow_end .frame{
  height: 170px;
  background-size: contain;
}
</style>

<script>
export default {
  data(){
    return {
      timer: 300,
      Timer: null,
    }
  },
  mounted() {
    this.reset();
  },
  computed: {
    minutes() {
      return Math.trunc(this.timer / 60);
    },
    seconds(){
      let t = String(Math.trunc(this.timer - Math.trunc(this.timer / 60) * 60));
      return (t.length < 2 ? '0' + t : t);
    },
  },
  methods: {
    cancel(param) {
      clearInterval(this.Timer);
      setTimeout(() => this.$emit("local_event", "WaitingVue", param), 200);
    },
    reset() {
      this.Timer = setInterval(() => {
        this.timer -= 1;
        if (this.timer <= 0){
          this.cancel(1);
        }
      }, 1000);
    }
  },
};
</script>
<template>
  <div class="upgrade_content">
    <div class="cont">
      <img src="../assets/Upgrade_assets/f_horse.svg" class="fhorse" />

      <div class = "wrapper">
        <div class="level_up">
          <span>LEVEL {{ level }}</span>
          <div class="lshadow"></div>
        </div>
        <div class="level_up">
          <span>RECORD {{ record_distance }}</span>
          <div class="lshadow"></div>
        </div>
        <div class="level_up">
          <span>UPGRADE {{ upgrade_cost }}</span>
          <div class="lshadow"></div>
        </div>
      </div>

      <div class="l_button" @click="upgrade()"></div>
    </div>
    <img class = "wood" src = "../assets/Upgrade_assets/upgrade.png">
  </div>
</template>

<style>
@import "../styles/UpgradeViewStyles.css";
</style>

<script>
import axios from "axios";

export default {
  name: "UpgradeVue",
  data() {
    return {
      level: 0,
      upgrade_cost: 0,
      record_distance: 0,
      //loaders
      img_loading: 0,
      imgs_loaded: false,
      api_responsed: false,
    };
  },
  async mounted() {
    this.getData("horses");

    let images = document.querySelectorAll("img");
    this.img_loading = images.length;
    for (let item of images) item.onload = this.img_loaded();
  },
  computed: {
    page_loaded() {
      return this.imgs_loaded && this.api_responsed;
    },
  },
  watch: {
    page_loaded() {
      this.$emit("component_loading", { name: "UpgradeView" });
    },
  },
  methods: {
    img_loaded() {
      this.img_loading--;
      if (this.img_loading == 0) this.imgs_loaded = true;
    },

    upgrade() {
      this.getData("horses/upgrade");
    },

    //api
    async getData(link) {
      this.initData = window.Telegram.WebApp.initData;

      axios.defaults.headers.common["X-User-Data"] = `${this.initData}`;

      await axios
        .get("https://cryptocowboy.ru/api/v1/" + link)
        .then((response) => {
          this.level = (response.data.level != null ? response.data.level : 0);
          this.upgrade_cost = (response.data.upgrade_cost != null ? response.data.upgrade_cost : 0)
          //console.log("on UpgradeVue get /" + link, response);
          if (link == "horses/upgrade") this.$emit("money_upd");
          if (link == "horses") {
            this.record_distance = (response.data.distance != null ? response.data.distance : 0)
            this.api_responsed = true;
          }
        })
        .catch((error) => {
          error;
          //console.log("on UpgradeVue get /" + link, error);
          this.api_responsed = false; //debug
        });
    },
  },
};
</script>

<template>
  <div class="wood_cont high_wood">
    <img class="wood" src="../assets/Upgrade_assets/upgrade.png" />
  </div>

  <div class="GoldBuy high_gold">
    <img src="../assets/MoneyImages/bank_img.png" class="bank">

    <div class="money_container">
      <div class="money_frame" v-for="elem in options" v-bind:key="elem" @click="Buy(elem.number)">
        <img src="../assets/frame_for_gold.svg" />
        <div class="coins_"><img :src="elem.src" /></div>
        <span>{{elem.gold}}</span>
      </div>
    </div>

    <div class="shadow_end" v-if="visible">
      <div class="frame">
        <img src="../assets/MoneyImages/want_to_buy.png" class="want_to_buy" />
        <div class="coins_"><img :src="options[choosen_option].src" /></div>
        <span>{{options[choosen_option].gold}}</span>
        <div class="continue_button" @click="Confirm()">
          <img src="../assets/svgs/buy.svg" />
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.bank{
  width: 314px;
  height: 164px;
  align-self: center;
  position: relative;
}
._frame {
  width: 100vw;
  height: 80vh;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.block {
  width: 290px;
  height: 60px;
  align-self: center;
  display: flex;
  justify-content: start;
  gap: 10px;
}
.block_text {
  display: flex;
  flex-direction: column;
  justify-content: end;
}
.block_text span {
  align-self: flex-start;
}

.GoldBuy .frame {
  position: absolute;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 150px;
}
.GoldBuy .frame span {
  color: white;
  position: relative;
  align-self: center;
  font-size: 20px;
  top: 10px;
}
.GoldBuy .frame .coins_ {
  position: relative;
  bottom: -10px;
  left: 0px;
  align-self: center;
}
.GoldBuy .frame .coins_ img {
  align-self: center;
}

.GoldBuy .continue_button {
  align-self: center;
  background-image: none;
  top: 20px;
}
.GoldBuy .shadow_end {
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.3);
}
.want_to_buy {
  width: 120px;
  align-self: center;
  height: 25px;
  bottom: 15px;
  position: relative;
}
.wood_cont{
  height: 100vh;
  position: absolute;
  z-index: 15;
}


.GoldBuy {
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 16;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.wood_cont .wood {
  width: 100vw;
  position: absolute;
}

.high_wood{
  z-index: 21;
}
.high_gold{
  z-index: 21;
}
.money_container {
  width: 90vw;
  height: 300px;
  align-self: center;
  position: relative;
  justify-items: center;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.money_frame {
  width: 90px;
  height: 120px;
  display: flex;
  position: relative;
  justify-content: center;
  align-self: center;
  font-size: 12px;
}
.money_container span {
  position: absolute;
  bottom: 35px;
  color:white;
}
.coins_ {
  height: 35px;
  width: 45px;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 55px;
  left: 25px;
}

.money_container div:active {
  -webkit-animation: Scale 0.1s;
  animation: Scale 0.1s;
  animation-timing-function: ease-in-out;
}

@keyframes Scale {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.9);
  }
}
</style>

<script>
import axios from "axios";

export default {
  data() {
    return {
      silver: 0,
      gold: 0,
      visible: false,

      options: [
        {
          src: "./MoneyImagesPublic/gold 1.png",
          gold: "100/1TON/Test",
          number: 0,
        },
        {
          src: "./MoneyImagesPublic/gold 2.png",
          gold: "200/2TON",
          number: 1,
        },
        {
          src: "./MoneyImagesPublic/gold 3.png",
          gold: "500/3TON",
          number: 2,
        },
        {
          src: "./MoneyImagesPublic/gold 4.png",
          gold: "1000/5TON",
          number: 3,
        },
        {
          src: "./MoneyImagesPublic/gold 5.png",
          gold: "2000/6TON",
          number: 4,
        },
        {
          src: "./MoneyImagesPublic/gold 6.png",
          gold: "5000/7TON",
          number: 5,
        },
      ],

      choosen_option: 1,
    };
  },
  methods:{
    Buy(number){
      this.visible = true;
      this.choosen_option = number;
    },

    Confirm(){
      this.visible = false;
      console.log("bought", this.options[this.choosen_option].value);
    },

    async GetPrices(link) {
      this.initData = window.Telegram.WebApp.initData;

      axios.defaults.headers.common["X-User-Data"] = `${this.initData}`;

      await axios
        .get("https://cryptocowboy.ru/api/v1/" + link)
        .then((response) => {
          /*console.log("on get/" + link, response);*/
          this.options = response.data;
          for (let i = 1; i < response.data.length; i++) {
            this.options[i].src = `./MoneyImagesPublic/gold ${(i > 6 ? 6 : i)}.png`;
            this.options[i].number = i - 1;
          }
        })
        .catch((error) => (/*console.log("on get/" + link, error)*/ error));
    },
  }
};
</script>

<template>
  <div class = "container">
    <Loading v-if="loader_visible && false"></Loading>

    <TopBar v-if="tab != 'RaceGameView' && tab != 'ShootoutView'" @setTab="setTab" @VisibilityToggler="VisibilityToggler" ref = "TopBar"></TopBar>
    <DonatePage v-if="donate_visible"></DonatePage>

    <div class="content">
      <v-tabs-window v-model="tab">
        <v-tabs-window-item value="RaceGameView">
          <Race_GameView @component_loading="component_loaded" @setTab="setTab" ref="RaceGame"></Race_GameView>
        </v-tabs-window-item>

        <v-tabs-window-item value="ClansView">
          <ClansView @component_loading="component_loaded" @setTab="setTab" ref="ClansView"></ClansView>
        </v-tabs-window-item>

        <v-tabs-window-item value="UpgradeView">
          <UpgradeView @component_loading="component_loaded" @money_upd="Money_upd"></UpgradeView>
        </v-tabs-window-item>

        <v-tabs-window-item value="MainMenuView">
          <MainMenu @component_loading="component_loaded" @setTab="setTab" @Redirect="Redirect" ref="MainMenu"></MainMenu>
        </v-tabs-window-item>

        <v-tabs-window-item value="StatisticsView">
          <StatisticsView @component_loading="component_loaded" @setTab="setTab" ref="StatisticsView"></StatisticsView>
        </v-tabs-window-item>

        <v-tabs-window-item value="ShootoutView">
          <ShootoutView @component_loading="component_loaded" @setTab="setTab" ref="ShootOut"></ShootoutView>
        </v-tabs-window-item>

      </v-tabs-window>
    </div>

    <Navigation v-if="tab != 'RaceGameView' && tab != 'ShootoutView' && !donate_visible" @setTab="setTab"></Navigation>
  </div>
</template>

<style>
@import "./styles/AppStyles.css";
</style>

<script>
import axios from "axios";

//components
import Navigation from "./components/NavigationVue.vue";
import TopBar from "./components/TopBar.vue";
import Loading from "./components/LoadingVue.vue";

import MainMenu from "./views/MainMenu.vue";
import UpgradeView from "./views/UpgradeView.vue";
import Race_GameView from "./views/Race_GameView.vue";
import ShootoutView from "./views/ShootoutView.vue";
import StatisticsView from "./views/StatisticsView.vue";
import DonatePage from "./components/DonatePage.vue";
import ClansView from "./views/ClansView.vue";

export default {
  name: "App",
  components: {
    Navigation,
    Loading,
    DonatePage,
    TopBar,
    //
    MainMenu,
    UpgradeView,
    Race_GameView,
    ShootoutView,
    StatisticsView,
    ClansView,
  },
  data() {
    return {
      //visibility controllers
      loader_visible: true, 
      loader_min_delay: 800,
      donate_visible: false,

      // tab/page controllers
      tab: "MainMenuView",
      loaded: {
        "MainMenuView": false,
        "UpgradeView": false,
        "RaceGameView": false,
        "ShootoutView": false,
        "StatisticsView": false,
        "ClansView": false,
      },

      //some data
      game_id: null,
    }
  },
  async mounted() { // user init
    this.SendInitialData("users");
  },
  watch: {
    tab(){ //tab controller (activates when tab changes)

      //loading after preloading
      this.loader_visible = true;
      if (this.loaded[this.tab])
        setTimeout(() => this.loader_visible = false, this.loader_min_delay);

      //refresh-func for RaceGame
      if (this.tab == "RaceGameView" && this.loaded[this.tab])
          this.$refs.RaceGame.assets_load();
      
      //refresh-func for MainMenu
      if (this.tab == "MainMenuView") 
        this.$refs.MainMenu.Refresh();

      //refresh-func for StatisticsView
      if (this.tab == "StatisticsView" && this.loaded[this.tab])
        this.$refs.StatisticsView.Refresh();
      
      //refresh-func for subcomponent TopBar
      if (this.tab != 'RaceGameView' && this.tab != 'ShootoutView')
        setTimeout(() => {this.$refs.TopBar.Refresh();}, 50); 
    }
  },
  methods: {
    //functions activated from components:

    // component loaded trigger
    component_loaded(data){
      console.log("loaded", data);
      this.loaded[data.name] = true;
      setTimeout(() => this.loader_visible = false, 100);
    },

    //tab change
    setTab(tab){
      this.tab = tab;
    },

    Redirect(data){
      this.game_id = data.id;
      console.log(this.game_id);
      this.setTab("ShootoutView");
    },

    //visibility toggler
    VisibilityToggler(data){
      if (data.ComponentName == "DonatePage")
        this.donate_visible = data.visible;
    },

    Money_upd(){
      this.$refs.TopBar.Refresh();
    },

    //this component inner func:

    //api: sends initial data
    async SendInitialData(link) {
      this.initData = window.Telegram.WebApp.initData;

      axios.defaults.headers.common["X-User-Data"] = `${this.initData}`;

      await axios
        .get("https://cryptocowboy.ru/api/v1/" + link)
        .then((response) => {/*console.log("on get /users", response)*/ response})
        .catch((error) => (/*console.log("on get /users", error)*/ error));
    },
  },
};
</script>

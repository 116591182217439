<template>
  <!-- <debugConsole :logs = logs ></debugConsole> -->

  <div class="MainMenu_content">
    <div class="midst">
      <div class="horse" @click="link_timeout('UpgradeView', 100)">
        <div class="bar">
          <div class="progress_bar">
            <div class="progress" :style="progress_len">
              <div class="shadow"></div>
            </div>
          </div>
          <div class="sign">
            <span>{{ level }}</span>
          </div>
        </div>
        <img src = "../assets/horse_2.png" class = "horse">
      </div>
      
      <div class="games">
        <div class="race" :style="{width:'156px', height:'64px'}" @click="link_timeout('RaceGameView', 200)"><span>RACE {{ race_left }}/12</span><img src="../assets/MainMenu_assets/frame.svg"></div>
        <div class="shootout" :style="{width:'156px', height:'64px'}" @click="visible.GameBet = true"><span>SH00U0TU</span><img src="../assets/MainMenu_assets/frame.svg"></div>
      </div>
    </div>
   
    <img src="../assets/MainMenu_assets/mount.png" class="mountain" />
    <img src="../assets/MainMenu_assets/desert.png" class="desert" />
    <img src="../assets/MainMenu_assets/road.png" class="road" />
    <img src="../assets/MainMenu_assets/gr.png" class="gr" />

    <AttemptsWarning v-if="visible.AttemptsWarning" @local_event="event"></AttemptsWarning>
    <GameBet v-if="visible.GameBet" @local_event="event"></GameBet>
    <WaitingVue v-if="visible.WaitingVue" @local_event="event"></WaitingVue>
  </div>
</template>

<style>
@import "../styles/MainMenuStyles.css";
</style>

<script>
import axios from "axios";
import AttemptsWarning from "@/components/AttemptsWarning.vue";
import GameBet from "@/components/GameBet.vue";
import WaitingVue from "@/components/WaitingVue.vue";
// import debugConsole from "@/components/debugConsole.vue"; // disable on release

export default {
  name: "MainMenu",
  components:{
    AttemptsWarning,
    GameBet,
    WaitingVue,
    // debugConsole, // disable on release
  },
  data() {
    return {
      level: 0,
      progress: 0,
      race_available: true,
      race_left: 12,
      bet_value: 0,

      game_search_socket: null,
      connection_open: true,
      logs: [],
      
      visible: {
        "AttemptsWarning": false,
        "GameBet": false,
        "WaitingVue": false,
      },
      //loaders
      img_loading: 0,
      imgs_loaded: false,
      api_responsed: 0,
    };
  },
  async mounted() {
    this.getData("horses");
    this.getData("horses/available");

    //to check the hash
    //this.logs.push([Date.now(), `[TgAppData]: ${window.Telegram.WebApp.initData}`]);

    let images = document.querySelectorAll("img");
    this.img_loading = images.length;
    for (let item of images) item.onload = this.img_loaded();
  },
  computed: {
    page_loaded() {
      return this.imgs_loaded && (this.api_responsed == 2);
    },
    progress_len() {
      return `width: ${this.progress}px;`;
    },
  },
  watch: {
    page_loaded() {
      this.$emit("component_loading", { name: "MainMenuView" });
    },
  },

  methods: {
    img_loaded() {
      this.img_loading--;
      if (this.img_loading == 0) this.imgs_loaded = true;
    },

    Refresh(){ //outside refresh func for App.vue 
      this.getData("horses");
      this.getData("horses/available");
    },

    event(component, param){
      if (component == "AttemptsWarning"){
        this.visible.AttemptsWarning = false;
      } else if (component == "GameBet"){
        this.bet_value = param;
        console.log(this.bet_value);
        this.visible.GameBet = false;
        this.create_socket();
        this.visible.WaitingVue = true;
      } else if (component == "WaitingVue"){
        if (param == 0 && this.connection_open){
          this.game_search_socket.close();
          this.logs.push([Date.now(), "[info]: Connection closed by user."]);
          console.log("%c[info]: Connection closed by user.", "color: green");
        }
        this.visible.WaitingVue = false;
      }
    },

    create_socket(){
      this.logs.push([Date.now(), "[info]: Trying to establish the connetction."]);
      console.log("%c[info]: Trying to establish the connetction.", "color: green");
      this.game_search_socket = new WebSocket(`wss://cryptocowboy.ru/api/v1/gunfights/find?${window.Telegram.WebApp.initData}`);
      
      // socket handlers
      this.game_search_socket.onopen = () => {
        this.game_search_socket.send(JSON.stringify({"gold": this.bet_value}));
        this.connection_open = true;

        this.logs.push([Date.now(),"[info]: Connetction established."]);
        console.log("%c[info]: Connetction established.", "color: green");
      };

      this.game_search_socket.onmessage = (event) => {
        this.logs.push([Date.now(), `[message] data received from server: ${event.data}`]);
        console.log(`%c[message] data received from server: ${event.data}.`, "color: green");

        if (event.data.slice(0, 12) == "PlayGunfight"){
          this.$emit("Redirect", {id: parseInt(event.data.slice(12))});
        }
        this.visible.WaitingVue = false;
      };

      this.game_search_socket.onclose = (event) => {
        this.connection_open = false;
        this.visible.WaitingVue = false;
        if (event.wasClean){
          this.logs.push([Date.now(), `[close] the connection was closed, code = ${event.code}.`]);
          console.log(`%c[close] the connection was closed, code: ${event.code}.`, "color: green");
        } else {
          this.logs.push([Date.now(), `[close] the connection was aborted, code = ${event.code}`]);
          console.log(`%c[close] the connection was aborted, code: ${event.code}`, "color: red");
        }
      };

      this.game_search_socket.onerror = (error) => {
        this.logs.push([Date.now(), `[error]: ${error}`]);
        console.log(`%c[error]: ${error}`, "color: red");
      };
    },
    
    //api
    async getData(link) {
      this.initData = window.Telegram.WebApp.initData;

      axios.defaults.headers.common["X-User-Data"] = `${this.initData}`;

      await axios
        .get("https://cryptocowboy.ru/api/v1/" + link)
        .then((response) => {
          if (link == "horses"){
            this.level = response.data.level;
            this.progress = Math.floor(response.data.level / 140);
            this.api_responsed += 1;
          } else if (link == "horses/available"){
            this.race_available = response.data.available;
            this.race_left = response.data.left;
            this.api_responsed += 1;
          }
          //console.log("on MainMenu get /" + link, response);
        })
        .catch((error) => {
          error;
          //console.log("on MainMenu get /" + link, error);
          this.api_responsed = 0; //debug
        });
    },

    //router
    link_timeout(link, timeout) {
      if (link == "RaceGameView"){
        if (this.race_available){
          setTimeout(() => {
            this.$emit("setTab", link);
          }, timeout);
        } else {
          this.visible.AttemptsWarning = true;
        }
      } else {
        setTimeout(() => {
          this.$emit("setTab", link);
        }, timeout);
      }
    },
  },
};
</script>

<template>
  <div class="shadow_end end_try">
    <div class="frame">
      <div class="skull"></div>
      <div class="center_menu">
        <div>The horse is tired, come back in two hours.</div>
        <div class="continue_button" @click="toggle_visible"></div>
      </div>
    </div>
  </div>
</template>

<style>
@import "../styles/EndGame.css";

.end_try .center_menu div:first-child {
  width: 150px;
  color: #fff;
  position: relative;
}
.end_try .continue_button {
  background-image: url("../assets/okay.png");
}
.end_try .center_menu .continue_button {
  left: 20px;
  top: 5px;
}
</style>

<script>
export default {
  methods: {
    toggle_visible() {
      setTimeout(() => this.$emit("local_event", "AttemptsWarning"), 200);
    },
  },
};
</script>

<template>
  <div class = "bg_shootout">
  <img class = "wood" src = "../assets/Shootout_assets/ginfight_game.png">
  </div> 
  <div class="EndShoot shootout_end" v-if="visible">
    <div class="box_shoot">
      <div class="fail">
        <div class="game_over"></div>
        <div class="cont_info_shoot">
          <div class="info_cont">
            <span>nickname</span>
            <div class="info_shadow"></div>
          </div>
          <div class="fail_status"></div>
        </div>
      </div>

      <div class="vs"></div>

      <div class="winner">
        <div class="winner_info">
          <div class="cont_info_shoot">
            <div class="info_cont">
              <span>nickname</span>
              <div class="info_shadow"></div>
            </div>
            <div class="winner_status"></div>
          </div>
          <div class="cont_info_shoot">
            <div class="info_cont">
              <span>500</span>
              <div class="info_shadow"></div>
            </div>
          </div>
        </div>
        <div
          class="continue_button"
          @click="link_timeout('MainMenuView', 100)"
        ></div>
      </div>
    </div>
  </div>

  <div class="Shootout_cont">
   
    <div class="up_shoot">
      <div class="money_box">
        <div class="money_img clock">
          <img src="../assets/Race_game_assets/clock.svg" />
        </div>
        <span class="value">{{ minutes }}:{{ seconds }}</span>
      </div>

      <div class="hearts_box">
        <div class="heart" v-if="healths_cnt >= 3"></div>
        <div class="heart broken_heart" v-if="healths_cnt <= 2"></div>
        <div class="heart" v-if="healths_cnt >= 2"></div>
        <div class="heart broken_heart" v-if="healths_cnt <= 1"></div>
        <div class="heart" v-if="healths_cnt >= 1"></div>
        <div class="heart broken_heart" v-if="healths_cnt == 0"></div>
      </div>
    </div>

    <div class="shoot_butt_container">
      <div class="shoot_butt">
        <div class="bang"></div>
        <div class="blue"></div>
        <div class="arrow"></div>
      </div>
    </div>



  </div>
   
</template>

<style>
@import "../styles/EndShootStyles.css";
@import "../styles/Shootout.css";
</style>

<script>
export default {
  name: "ShootoutVue",
  componets: {},
  data() {
    return {
      //loaders
      img_loading: 0,
      imgs_loaded: true,
      api_responsed: true,

      //page data
      timer: 300,
      healths_cnt: 3,
      game_socket: null, 

      visible: true,
    };
  },
  computed: {
    minutes() {
      return Math.trunc(this.timer / 60);
    },
    seconds() {
      let t = String(Math.trunc(this.timer - Math.trunc(this.timer / 60) * 60));
      return t.length < 2 ? "0" + t : t;
    },
  },
  mounted() {
    this.$emit("component_loading", { name: "ShootoutView" });

    this.create_socket();
  },
  methods: {
    link_timeout(link, timeout) {
      setTimeout(() => {
        this.$emit("setTab", link);
      }, timeout);
    },

    create_socket() {
      this.game_socket = new WebSocket(
        `https://cryptocowboy.ru/api/v1/gunfights/start?${window.Telegram.WebApp.initData}`
      );

      this.game_socket.onopen = () => {
        this.game_socket.send({
          type: "start",
          data: {
            game_id: 1,
          },
        });
      };
    },
  },
};
</script>
